import React, { useEffect, useState } from 'react';
import {
    getUsers,
    getExams,
    assignTeacher,
    updateUserRole,
    updateExamStatus,
    deleteExam,
    deleteUser,
    uploadAdminRevision
} from '../services/api';
import { Table, Form, Button, Modal, Tab, Tabs } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import { converDate } from '../utils/dateUtils';

const AdminDashboard = () => {
    const [users, setUsers] = useState([]);
    const [exams, setExams] = useState([]);
    const [currentPageUsers, setCurrentPageUsers] = useState(1);
    const [currentPageExams, setCurrentPageExams] = useState(1);
    const rowsPerPage = 20;
    const [showModal, setShowModal] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [deleteType, setDeleteType] = useState('');
    const [uploadState, setUploadState] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            const usersData = await getUsers();
            const examsData = await getExams();
            setUsers(usersData.data);
            setExams(sortExams(examsData.data));
        };
        fetchData();
    }, []);

    const handleRoleChange = async (userId, role) => {
        await updateUserRole(userId, { role });
        setUsers(users.map(user => (user._id === userId ? { ...user, role } : user)));
    };

    const handleTeacherAssignment = async (examId, teacherId) => {
        await assignTeacher(examId, { teacherId });
        const updatedExams = exams.map(exam => {
            if (exam._id === examId) {
                return { ...exam, assignedTeacher: { _id: teacherId, username: users.find(user => user._id === teacherId)?.username } };
            }
            return exam;
        });
        setExams(sortExams(updatedExams));
    };

    const handleExamStatusUpdate = async (examId, status) => {
        await updateExamStatus(examId, { status });
        const updatedExams = exams.map(exam => (exam._id === examId ? { ...exam, status } : exam));
        setExams(sortExams(updatedExams));
    };

    const handleDelete = async () => {
        if (deleteType === 'exam') {
            await deleteExam(deleteItemId);
            setExams(exams.filter(exam => exam._id !== deleteItemId));
        } else if (deleteType === 'user') {
            await deleteUser(deleteItemId);
            setUsers(users.filter(user => user._id !== deleteItemId));
        }
        setShowModal(false);
    };

    const sortExams = (exams) => {
        return exams.sort((a, b) => {
            if (a.status === 'pending' && b.status !== 'pending') {
                return -1;
            } else if (a.status !== 'pending' && b.status === 'pending') {
                return 1;
            } else {
                return 0;
            }
        });
    };
		

    const paginate = (data, page, rowsPerPage) => {
        const startIndex = (page - 1) * rowsPerPage;
        return data.slice(startIndex, startIndex + rowsPerPage);
    };

    const handlePageChange = (type, direction) => {
        if (type === 'users') {
            setCurrentPageUsers(prevPage => prevPage + direction);
        } else if (type === 'exams') {
            setCurrentPageExams(prevPage => prevPage + direction);
        }
    };

    const handleFileChange = (examId, file) => {
        setUploadState(prevState => ({
            ...prevState,
            [examId]: {
                ...prevState[examId],
                file,
            }
        }));
    };

    const handleEditTypeChange = (examId, editType) => {
        setUploadState(prevState => ({
            ...prevState,
            [examId]: {
                ...prevState[examId],
                editType,
            }
        }));
    };

    const handleFileUpload = async (examId) => {
        const { file, editType } = uploadState[examId] || {};
        if (!file || !editType) return;
        const formData = new FormData();
        formData.append('adminFile', file);
        formData.append('editType', editType);
        await uploadAdminRevision(examId, formData);

        const examsData = await getExams();
        setExams(sortExams(examsData.data));

        setUploadState(prevState => ({
            ...prevState,
            [examId]: { file: null, editType: 'author' } // Reset after upload
        }));
        alert('Uploaded successfully');
    };

    const renderUsersTable = () => (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Người dùng</th>
                    <th>Họ tên</th>
                    <th>Email</th>
                    <th>Số Điện Thoại</th>
                    <th>Đơn vị Công Tác</th>
                    <th>Quyền</th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {paginate(users, currentPageUsers, rowsPerPage).map(user => (
                    user.role !== 'admin' && (
                        <tr key={user._id}>
                            <td>{user.username}</td>
                            <td>{user.fullName}</td>
                            <td>{user.email}</td>
                            <td>{user.phone}</td>
                            <td>{user.gender}</td>
                            <td>{user.role === 'student' ? 'Tác giả' : 'Phản Biện'}</td>
                            <td>
                                <Form.Control as="select" value={user.role || ''} onChange={(e) => handleRoleChange(user._id, e.target.value)}>
                                    <option value="">Select</option>
                                    <option value="student">Tác Giả</option>
                                    <option value="teacher">Phản Biện</option>
                                </Form.Control>
                            </td>
                            <td>
                                <Button variant="danger" onClick={() => { setDeleteType('user'); setDeleteItemId(user._id); setShowModal(true); }}>
                                    <Trash />
                                </Button>
                            </td>
                        </tr>
                    )
                ))}
            </tbody>
        </Table>
    );

    const renderAuthorsTable = () => {
        const paginatedExams = paginate(exams.filter(exam => exam.student.role === 'student'), currentPageExams, rowsPerPage);

        return (
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Tác giả</th>
                        <th>Bài Viết</th>
                        <th>Chỉnh sửa</th>
                        <th>Ý Kiến của Admin</th>
                        <th>Phân Quyền</th>
                        <th></th>
                        <th>Trạng thái</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedExams.map(exam => {
                        const fileName = exam.filePath.replace('/home/rev44306/domains/review.mtu.edu.vn/public_html/uploads/', '');
                        const fileNameShorten = fileName.substring(fileName.indexOf('-') + 1);
                        const dateString = fileName.match(/\d{13}/)[0];
                        const formatted_date = converDate(dateString);
    
                        // Filter admin files by editType "reviewer"
                        const adminFilesReviewer = exam.adminFiles?.filter(file => file.editType === 'reviewer') || [];
    
                        return (
                            <tr key={exam._id}>
                                <td>{exam.student && exam.student.username}</td>
                                <td>
                                    <a href={exam.filePath.replace('/home/rev44306/domains/review.mtu.edu.vn/public_html', '')} target="_blank" rel="noopener noreferrer">
                                        <span className="filename-truncate">{formatted_date} - {fileNameShorten}</span>
                                    </a>
                                </td>
                                <td>
                                    <ul className="file-list">
                                        {exam.revisions.map((revision, index) => {
                                            const fileName = revision.filePath.replace('/home/rev44306/domains/review.mtu.edu.vn/public_html/uploads/', '');
                                            const fileNameShorten = fileName.substring(fileName.indexOf('-') + 1);
                                            const revisiondate = fileName.match(/\d{13}/)[0];
                                            const revisiond = converDate(revisiondate);
                                            return (
                                                <li key={index}>
                                                    <a href={revision.filePath.replace('/home/rev44306/domains/review.mtu.edu.vn/public_html', '')} target="_blank" rel="noopener noreferrer">{revisiond} - {fileNameShorten}</a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </td>
                                <td>
                                    <ul className="file-list">
                                        {adminFilesReviewer.map((file, index) => (
                                            <li key={index}>
                                                <a href={file.filePath.replace('/home/rev44306/domains/review.mtu.edu.vn/public_html', '')} target="_blank" rel="noopener noreferrer">
                                                    Admin Chỉnh Sửa {index + 1}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </td>
                                <td>
                                    <Form.Control as="select" value={exam.assignedTeacher?._id || ''} onChange={(e) => handleTeacherAssignment(exam._id, e.target.value)}>
                                        <option value="">Chọn</option>
                                        {users.filter(user => user.role === 'teacher').map(teacher => (
                                            <option key={teacher._id} value={teacher._id}>{teacher.username}</option>
                                        ))}
                                    </Form.Control>
                                </td>
                                <td>
                                    <Form.Group>
                                        <Form.Control type="file" onChange={(e) => 
                                            {
                                                handleFileChange(exam._id, e.target.files[0])
                                                handleEditTypeChange(exam._id, "author")
                                            }
                                        } />
                                        <Button 
                                            variant="primary" 
                                            onClick={() => handleFileUpload(exam._id)}
                                        >
                                            Upload
                                        </Button>
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Control as="select" value={exam.status || ''} onChange={(e) => handleExamStatusUpdate(exam._id, e.target.value)}>
                                        <option value="">Select</option>
                                        <option value="pending">Đang xử lý</option>
                                        <option value="pass">Đạt</option>
                                        <option value="fail">Không đạt</option>
                                    </Form.Control>
                                </td>
                                <td>
                                    <Button variant="danger" onClick={() => { setDeleteType('exam'); setDeleteItemId(exam._id); setShowModal(true); }}>
                                        <Trash />
                                    </Button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        );
    };

    const renderReviewersTable = () => {
        const paginatedExams = paginate(exams.filter(exam => exam.assignedTeacher && exam.assignedTeacher.role === 'teacher'), currentPageExams, rowsPerPage);

        return (
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Tên của Reviewer</th>
                        <th>Bài Viết</th>
                        <th>Ý Kiến</th>
                        <th>Ý Kiến của Admin</th>
                        <th></th>
                        <th>Trạng thái</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedExams.map(exam => {
                        // Filter admin files by editType "author"
                        const adminFiles = exam.adminFiles?.filter(file => file.editType === 'author') || [];
                        const firstAdminFile = adminFiles[0];
                        const remainingAdminFiles = adminFiles.slice(1);

                        const firstFileDate = firstAdminFile ? converDate(firstAdminFile.filePath.match(/\d{13}/)[0]) : '';
                        const firstFileShorten = firstAdminFile ? firstAdminFile.filePath.split('/').pop().split('-').slice(1).join('-') : '';

                        return (
                            <tr key={exam._id}>
                                <td>{exam.assignedTeacher && exam.assignedTeacher.username}</td>
                                <td>
                                    {firstAdminFile ? (
                                        <a href={firstAdminFile.filePath.replace('/home/rev44306/domains/review.mtu.edu.vn/public_html', '')} target="_blank" rel="noopener noreferrer">
                                            <span className="filename-truncate">{firstFileDate} - {firstFileShorten}</span>
                                        </a>
                                    ) : (
                                        <span>Chưa có bài chỉnh sửa đầu tiên</span>
                                    )}
                                </td>
                                <td>
                                    <ul className="file-list">
                                        {exam.feedbacks.map((feedback, index) => {
                                            const fileName = feedback.filePath.replace('/home/rev44306/domains/review.mtu.edu.vn/public_html/uploads/', '');
                                            const fileNameShorten = fileName.substring(fileName.indexOf('-') + 1);
                        
                                            const feedbackDate = converDate(fileName.match(/\d{13}/)[0]);
                                            return (
                                                <li key={index}>
                                                    <a href={feedback.filePath.replace('/home/rev44306/domains/review.mtu.edu.vn/public_html', '')} target="_blank" rel="noopener noreferrer">{feedbackDate} - {fileNameShorten}</a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </td>
                                <td>
                                    <ul className="file-list">
                                        {remainingAdminFiles.map((file, index) => {
                                            const revisionFileName = file.filePath.split('/').pop().split('-').slice(1).join('-');
                                            const revisionDate = converDate(file.filePath.match(/\d{13}/)[0]);
                                            return (
                                                <li key={index}>
                                                    <a href={file.filePath.replace('/home/rev44306/domains/review.mtu.edu.vn/public_html', '')} target="_blank" rel="noopener noreferrer">
                                                        {revisionDate} - {revisionFileName}
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </td>
                                <td>
                                    <Form.Group>
                                        <Form.Control type="file" onChange={(e) => 
                                            {
                                                handleFileChange(exam._id, e.target.files[0])
                                                handleEditTypeChange(exam._id, "reviewer")
                                            }
                                        }/>
                                        <Button 
                                            variant="primary" 
                                            onClick={() => handleFileUpload(exam._id)}
                                        >
                                            Upload
                                        </Button>
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Control as="select" value={exam.status || ''} onChange={(e) => handleExamStatusUpdate(exam._id, e.target.value)}>
                                        <option value="">Select</option>
                                        <option value="pending">Đang xử lý</option>
                                        <option value="pass">Đạt</option>
                                        <option value="fail">Không đạt</option>
                                    </Form.Control>
                                </td>
                                <td>
                                    <Button variant="danger" onClick={() => { setDeleteType('exam'); setDeleteItemId(exam._id); setShowModal(true); }}>
                                        <Trash />
                                    </Button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        );
    };

    const renderPassedExamsTable = () => {
        const paginatedExams = paginate(exams.filter(exam => exam.status === 'pass'), currentPageExams, rowsPerPage);
    
        return (
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Tác giả</th>
                        <th>Bài viết</th>
                        <th>Bài kết quả</th>
                        <th>Trạng thái</th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedExams.map(exam => {
                        const fileName = exam.filePath.replace('/home/rev44306/domains/review.mtu.edu.vn/public_html/uploads/', '');
                        const fileNameShorten = fileName.substring(fileName.indexOf('-') + 1);
                        const dateString = fileName.match(/\d{13}/)[0];
                        const formatted_date = converDate(dateString);
                        const lastAuthorEdit = exam.revisions?.pop();
    
                        return (
                            <tr key={exam._id}>
                                <td>{exam.student && exam.student.username}</td>
                                <td>
                                    <a href={exam.filePath.replace('/home/rev44306/domains/review.mtu.edu.vn/public_html', '')} target="_blank" rel="noopener noreferrer">
                                        <span className="filename-truncate">{formatted_date} - {fileNameShorten}</span>
                                    </a>
                                </td>
                                <td>
                                    {lastAuthorEdit ? (
                                        <a href={lastAuthorEdit.filePath.replace('/home/rev44306/domains/review.mtu.edu.vn/public_html', '')} target="_blank" rel="noopener noreferrer">
                                            {converDate(lastAuthorEdit.filePath.match(/\d{13}/)[0])} - {lastAuthorEdit.filePath.split('/').pop().split('-').slice(1).join('-')}
                                        </a>
                                    ) : (
                                        <span>Không có chỉnh sửa</span>
                                    )}
                                </td>
                                <td>Đạt</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        );
    };
    

    const renderFailedExamsTable = () => {
        const paginatedExams = paginate(exams.filter(exam => exam.status === 'fail'), currentPageExams, rowsPerPage);
    
        return (
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Tác giả</th>
                        <th>Bài viết</th>
                        <th>Bài kết quả</th>
                        <th>Trạng thái</th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedExams.map(exam => {
                        const fileName = exam.filePath.replace('/home/rev44306/domains/review.mtu.edu.vn/public_html/uploads/', '');
                        const fileNameShorten = fileName.substring(fileName.indexOf('-') + 1);
                        const dateString = fileName.match(/\d{13}/)[0];
                        const formatted_date = converDate(dateString);
    
                        const lastAuthorEdit = exam.revisions.pop();
    
                        return (
                            <tr key={exam._id}>
                                <td>{exam.student && exam.student.username}</td>
                                <td>
                                    <a href={exam.filePath.replace('/home/rev44306/domains/review.mtu.edu.vn/public_html', '')} target="_blank" rel="noopener noreferrer">
                                        <span className="filename-truncate">{formatted_date} - {fileNameShorten}</span>
                                    </a>
                                </td>
                                <td>
                                    {lastAuthorEdit ? (
                                        <a href={lastAuthorEdit.filePath.replace('/home/rev44306/domains/review.mtu.edu.vn/public_html', '')} target="_blank" rel="noopener noreferrer">
                                            {converDate(lastAuthorEdit.filePath.match(/\d{13}/)[0])} - {lastAuthorEdit.filePath.split('/').pop().split('-').slice(1).join('-')}
                                        </a>
                                    ) : (
                                        <span>Không có chỉnh sửa</span>
                                    )}
                                </td>
                                <td>Không Đạt</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        );
    };
    

    return (
        <div>
            <Tabs defaultActiveKey="users" id="admin-dashboard-tabs">
                <Tab eventKey="users" title="Quản lý người dùng">
                    {renderUsersTable()}
                </Tab>
                <Tab eventKey="authors" title="Quản lý tác giả">
                    {renderAuthorsTable()}
                </Tab>
                <Tab eventKey="reviewers" title="Quản lý phản biện">
                    {renderReviewersTable()}
                </Tab>
                <Tab eventKey="passed" title="Bài Viết Đạt">
                    {renderPassedExamsTable()}
                </Tab>
                <Tab eventKey="failed" title="Bài Viết Không Đạt">
                    {renderFailedExamsTable()}
                </Tab>
            </Tabs>

            {/* Pagination and Modal for Deletion */}
            <div className="my-4 d-flex align-items-center justify-content-center">
                <Button onClick={() => handlePageChange('exams', -1)} disabled={currentPageExams === 1}>Trang trước</Button>
                <span className="mx-4">{currentPageExams}</span>
                <Button onClick={() => handlePageChange('exams', 1)} disabled={paginate(exams, currentPageExams, rowsPerPage).length < rowsPerPage}>Trang tiếp theo</Button>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Xác nhận</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc chắn muốn xoá vĩnh viễn mục này?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Hủy</Button>
                    <Button variant="primary" onClick={handleDelete}>Xoá</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AdminDashboard;
