import React, { useEffect, useState } from 'react';
import { getTeacherExams, uploadFeedback } from '../services/api';
import { Table, Button, Form, Badge } from 'react-bootstrap';
import ZaloChat from '../components/ZaloChat';
import { converDate } from '../utils/dateUtils';

const TeacherExams = () => {
    const [exams, setExams] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const userId = localStorage.getItem('userId');
            const response = await getTeacherExams(userId);
            setExams(sortExams(response.data));
        };

        fetchData();
    }, []);

    const sortExams = (exams) => {
        return exams.sort((a, b) => {
            if (a.status === 'pending' && b.status !== 'pending') {
                return -1;
            } else if (a.status !== 'pending' && b.status === 'pending') {
                return 1;
            } else {
                return 0;
            }
        });
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleSubmitFeedback = async (examId) => {
        if (!selectedFile) return;

        const formData = new FormData();
        formData.append('feedbackFile', selectedFile);

        try {
            await uploadFeedback(examId, formData);
            alert('Feedback uploaded successfully');
            setSelectedFile(null); // Clear the selected file
            window.location.reload(); // Refresh the page to show the updated feedback
        } catch (error) {
            alert('Error uploading feedback');
        }
    };

    return (
        <div>
            <ZaloChat phoneNumber={'0909563055'} />
            <h2>Danh sách bài viết</h2>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Trạng Thái</th>
                        <th>Bài Thi</th>
                        <th>Chỉnh Sửa</th>
                        <th>Ý Kiến</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {exams.map(exam => {
                        // Filter admin files by editType "author"
                        const adminFiles = exam.adminFiles?.filter(file => file.editType === 'author') || [];
                        
                        // Get the first admin file for "Bài Thi" column
                        const firstAdminFile = adminFiles[0];
                        
                        // Remaining admin files for "Chỉnh Sửa của Admin" column
                        const remainingAdminFiles = adminFiles.slice(1);

                        const firstFileDate = firstAdminFile ? converDate(firstAdminFile.filePath.match(/\d{13}/)[0]) : '';
                        const firstFileShorten = firstAdminFile ? firstAdminFile.filePath.split('/').pop().split('-').slice(1).join('-') : '';

                        return (
                            <tr key={exam._id}>
                                <td>
                                    {exam.status === 'pass' && <Badge bg="success">Đạt</Badge>}
                                    {exam.status === 'fail' && <Badge bg="danger">Không Đạt</Badge>}
                                    {exam.status === 'pending' && <Badge bg="warning">Đang Phản Biện</Badge>}
                                </td>
                                <td>
                                    {firstAdminFile ? (
                                        <a href={firstAdminFile.filePath.replace('/home/rev44306/domains/review.mtu.edu.vn/public_html', '')} target="_blank" rel="noopener noreferrer">
                                            <span className="filename-truncate">{firstFileDate} - {firstFileShorten}</span>
                                        </a>
                                    ) : (
                                        <span>Chưa có bài chỉnh sửa đầu tiên</span>
                                    )}
                                </td>
                                <td>
                                    <ul>
                                        {remainingAdminFiles.map((file, index) => {
                                            const fileName = file.filePath.replaceAll('/home/rev44306/domains/review.mtu.edu.vn/public_html/uploads/','')
                                            const fileNameShorten = fileName.substring(fileName.indexOf('-')+1)
                                            const revisionDate = converDate(file.filePath.match(/\d{13}/)[0]);
                                            return (
                                                <li key={index}>
                                                    <a href={file.filePath.replace('/home/rev44306/domains/review.mtu.edu.vn/public_html', '')} target="_blank" rel="noopener noreferrer">
                                                        {revisionDate} - {fileNameShorten}
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </td>
                                <td>
                                    {exam.feedbacks && <ul>
                                        {exam.feedbacks.map((feedback, index) => {
                                            const fileName = feedback.filePath.replace('/home/rev44306/domains/review.mtu.edu.vn/public_html/uploads/', '');
                                            const fileNameShorten = fileName.substring(fileName.indexOf('-') + 1);
                                            const feedbackDate = converDate(fileName.match(/\d{13}/)[0]);
                                            return (
                                                <li key={index}>
                                                    <a href={feedback.filePath.replace('/home/rev44306/domains/review.mtu.edu.vn/public_html', '')} target="_blank" rel="noopener noreferrer">
                                                        {feedbackDate} - {fileNameShorten}
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>}
                                </td>
                                <td>
                                    {exam.status === 'pending' &&
                                        <>
                                            {exam.revisions.length < 3 ? (
                                                <>
                                                    <Form.Control type="file" onChange={handleFileChange} />
                                                    <br />
                                                    <Button onClick={() => handleSubmitFeedback(exam._id)}>Upload</Button>
                                                </>
                                            ) : (
                                                <span>Đã đạt số lần tối đa</span>
                                            )}
                                        </>
                                    }
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
};

export default TeacherExams;
