import React, { useState } from 'react';
import { registerUser } from '../services/api';
import { Form, Button } from 'react-bootstrap';

const Register = () => {
    const [formData, setFormData] = useState({ username: '', email: '', password: '', role: 'student', fullName: '',
        gender: '',
        phone: '' });


    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await registerUser(formData);
            alert('User registered successfully');
        } catch (error) {
            alert('Error registering user');
        }
    };

    return (
        <Form className="formGeneral" onSubmit={handleSubmit}>
            <Form.Group controlId="username">
                <Form.Label>Tài Khoản</Form.Label>
                <Form.Control type="text" name="username" placeholder="Enter username" value={formData.username} onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="password">
                <Form.Label>Mật Khẩu</Form.Label>
                <Form.Control type="password" name="password" placeholder="Enter password" value={formData.password} onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" name="email" placeholder="Enter email" value={formData.email} onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="fullName">
                <Form.Label>Họ và Tên</Form.Label>
                <Form.Control type="text" name="fullName" value={formData.fullName} onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="gender">
                <Form.Label>Đơn Vị Công Tác</Form.Label>
                <Form.Control type="text" name="gender" value={formData.gender} onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="phone">
                <Form.Label>Số Điện Thoại</Form.Label>
                <Form.Control type="text" name="phone" value={formData.phone} onChange={handleChange} required />
            </Form.Group>
            <Button variant="primary" type="submit">
                Đăng ký
            </Button>
        </Form>
    );
};

export default Register;
