import React, { useEffect, useState } from 'react';
import { getStudentExams, uploadRevision, deleteExam } from '../services/api';
import { Table, Button, Form, Badge } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import ZaloChat from '../components/ZaloChat';
import UploadExam from './UploadExam';
import { converDate } from '../utils/dateUtils';

const StudentExams = () => {
    const [exams, setExams] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);

    const fetchData = async () => {
        const userId = localStorage.getItem('userId');
        const response = await getStudentExams(userId);
        setExams(response.data);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleDelete = async (examId) => {
        try {
            await deleteExam(examId);
            setExams(exams.filter(exam => exam._id !== examId));
            alert('Exam deleted successfully');
        } catch (error) {
            alert('Error deleting exam');
        }
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleSubmitRevision = async (examId) => {
        if (!selectedFile) return;

        const formData = new FormData();
        formData.append('revisionFile', selectedFile);

        try {
            await uploadRevision(examId, formData);
            alert('Revision uploaded successfully');
            setSelectedFile(null); // Clear the selected file
            fetchData(); // Refresh the data without reloading the page
        } catch (error) {
            alert('Error uploading revision');
        }
    };

    return (
        <>
            <ZaloChat phoneNumber={'0909563055'} />
            <div className="mb-4">
                <h2 className="mt-4">Gửi Bài</h2>
                <UploadExam onUploadSuccess={fetchData} /> {/* Pass the callback here */}
                <p className="mt-2"><strong>Vui lòng file không đặt tên Tiếng Việt, chỉ hỗ trợ định dạng PDF và DOCX</strong></p>
            </div>
            <hr />
            <h2 className="mt-4">Danh Sách Bài Viết</h2>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Trạng Thái</th>
                        <th>Bài thi</th>
                        <th>Ý Kiến</th>
                        <th>Chỉnh sửa</th>
                        <th>Upload Chỉnh sửa</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {exams.map(exam => {
                        const fileName = exam.filePath.replaceAll('/home/rev44306/domains/review.mtu.edu.vn/public_html/uploads/','')
                        const fileNameShorten = fileName.substring(fileName.indexOf('-')+1)
                        let dateString = fileName.match(/\d{13}/)[0];
                        let formatted_date = converDate(dateString);
                        const adminFeedbacks = exam.adminFiles?.filter(file => file.editType === 'reviewer') || [];

                        return (
                            <tr key={exam._id}>
                                <td>
                                    {exam.status === 'pass' && <Badge bg="success">Đạt</Badge>}
                                    {exam.status === 'fail' && <Badge bg="danger">Không Đạt</Badge>}
                                    {exam.status === 'pending' && <Badge bg="warning">{exam.adminFiles.length > 0 ? "Đang Phản Biện" : "Đang Xử Lý" }</Badge>}
                                </td>
                                <td>
                                    <a href={`${exam.filePath.replaceAll('/home/rev44306/domains/review.mtu.edu.vn/public_html','')}`} target="_blank" rel="noopener noreferrer">
                                        <span className="filename-truncate">{formatted_date} - {fileNameShorten}</span>
                                    </a>
                                </td>

                                <td>
                                    {adminFeedbacks && 
                                    <ul>
                                        {adminFeedbacks.map((file, index) => {
                                            const fileName = file.filePath.replace('/home/rev44306/domains/review.mtu.edu.vn/public_html/uploads/', '');
                                            const fileNameShorten = fileName.substring(fileName.indexOf('-') + 1);
                                            let feedbackDate = fileName.match(/\d{13}/)[0];
                                            let formattedFeedbackDate = converDate(feedbackDate);
                                            return (
                                                <li key={index}>
                                                    <a href={file.filePath.replaceAll('/home/rev44306/domains/review.mtu.edu.vn/public_html', '')} target="_blank" rel="noopener noreferrer">
                                                        {formattedFeedbackDate} - {fileNameShorten}
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                    }
                                </td>
                                <td>
                                    <ul>
                                        {exam.revisions.map((revision, index) => {
                                            const fileName = revision.filePath.replace('/home/rev44306/domains/review.mtu.edu.vn/public_html/uploads/', '');
                                            let revisiondate = fileName.match(/\d{13}/)[0];
                                            let revisiond = converDate(revisiondate);
                                            return (
                                                <li key={index}>
                                                    <a href={`${revision.filePath.replaceAll('/home/rev44306/domains/review.mtu.edu.vn/public_html','')}`} target="_blank" rel="noopener noreferrer">Chỉnh sửa lần {index + 1} - {revisiond}</a>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </td>
                                <td>
                                    {exam.status === 'pending' &&
                                        <>
                                            {exam.revisions.length < 3 ? (
                                                <>
                                                    <Form.Control type="file" onChange={handleFileChange} />
                                                    <br />
                                                    <Button onClick={() => handleSubmitRevision(exam._id)}>Upload</Button>
                                                </>
                                            ) : (
                                                <span>Đạt tối đa số lần chỉnh sửa</span>
                                            )}
                                        </>
                                    }
                                </td>
                                <td>
                                    <Button variant="danger" onClick={() => handleDelete(exam._id)}>
                                        <Trash />
                                    </Button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </>
    );
};

export default StudentExams;
