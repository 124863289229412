// src/contexts/UserContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';

// Tạo context
const UserContext = createContext();

// Provider component
export const UserProvider = ({ children }) => {
    const [user, setUser] = useState({
        role: '',
        userName: '',
        token: '',
    });

    useEffect(() => {
        // Đọc dữ liệu từ localStorage khi context khởi tạo
        const token = localStorage.getItem('token');
        const userRole = localStorage.getItem('userRole');
        const userName = localStorage.getItem('userName');

        if (token && userRole && userName) {
            setUser({
                role: userRole,
                userName: userName,
                token: token,
            });
        }
    }, []);

    const login = (userData) => {
        setUser({
            role: userData.role,
            userName: userData.name,
            token: userData.token,
        });
        localStorage.setItem('token', userData.token);
        localStorage.setItem('userId', userData.userId); // Save user ID
        localStorage.setItem('userRole', userData.role); 
        localStorage.setItem('userName', userData.name); 
    };

    const logout = () => {
        setUser({
            role: '',
            userName: '',
            token: '',
        });
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('userRole');
        localStorage.removeItem('userName');
    };

    return (
        <UserContext.Provider value={{ user, login, logout }}>
            {children}
        </UserContext.Provider>
    );
};

// Hook để sử dụng context
export const useUser = () => useContext(UserContext);
