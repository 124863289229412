import React, { useState, useEffect } from 'react';
import { uploadExam, getStudentExams } from '../services/api';
import { Form, Button, Alert } from 'react-bootstrap';

const UploadExam = ({ onUploadSuccess }) => { // Accept the callback as a prop
    const [file, setFile] = useState(null);
    const [examCount, setExamCount] = useState(0);
    const [error, setError] = useState('');
    const [studentId, setStudentId] = useState('');

    useEffect(() => {
        const userId = localStorage.getItem('userId');
        if (userId) {
            setStudentId(userId);
            const fetchExamCount = async () => {
                const { data } = await getStudentExams(userId);
                setExamCount(data.length);
            };
    
            fetchExamCount();
        }
    }, [studentId]);
    
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (examCount >= 5) {
            setError('You can only upload up to 5 exams.');
            return;
        }
        
        const formData = new FormData();
        formData.append('examFile', file);
        formData.append('student', studentId); 

        try {
            await uploadExam(formData);
            setExamCount(examCount + 1);
            alert('Exam uploaded successfully');
            if (onUploadSuccess) {
                onUploadSuccess(); // Call the callback to inform the parent component
            }
        } catch (error) {
            alert('Error uploading exam');
        }
    };

    return (
        <>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="examFile">
                    <Form.Control type="file" onChange={handleFileChange} required />
                </Form.Group>
                <br />
                <Button variant="primary" type="submit">
                    Tải lên
                </Button>
            </Form>
        </>
    );
};

export default UploadExam;
